<template>
  <div class="back-to-top" @click="scrollToTop" v-if="showButton">
    <div class="icon">
      <IconSVG handle="back_to_top" />
    </div>
  </div>
</template>

<script>
import IconSVG from 'Components/icon_svg/IconSVG.vue';

export default {
  components: {
    IconSVG
  },
  data() {
    return {
      showButton: false,
    };
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.showButton = window.scrollY > 100;
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.back-to-top {
  position: fixed;
  bottom: px_to_rem(14);
  right: px_to_rem(20);
  background-color: $color_black;
  color: #fff;
  border-radius: 50%;
  width: px_to_rem(44);
  height: px_to_rem(44);
  cursor: pointer;
  z-index: 999;
  overflow: hidden;
  border: 2px solid $color_yellow; 

  .text {
    display: flex; 
    align-items: center;
    justify-content: center;
    font-size: px_to_rem(10);
    text-align: center;
    line-height: px_to_rem(10);
    opacity: 0;
    padding-top: px_to_rem(10);
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;

    .c_icon_svg {
      width: px_to_rem(13);
      height: px_to_rem(23);
    }
  }
}
</style>
